// This ensures that the icon CSS is loaded immediately before attempting to render icons
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";

import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/700.css';

// Supports weights 100-900
import '@fontsource-variable/montserrat';

import './src/sass/style.scss'

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false;